import React, { useState } from 'react';
import CheckIcon from '@mui/icons-material/Check';

// SECTEUR BUTTON
const SelectableButton = ({texte}) => {
  const [isSelected, setIsSelected] = useState(false);

  const handleClick = () => {
    setIsSelected(!isSelected);
  };

  return (
    <div className="flex items-center justify-center p-4">
      <button
        onClick={handleClick}
        className={`flex items-center px-4 py-2 rounded-lg border-2 m-[-10px]
          ${isSelected ? 'bg-secondary-stroke border-[#001751] text-primary-bleu' : 'bg-white border-secondary-stroke text-primary-bleu'} 
          transition-all duration-300`}
          >
        {isSelected && <CheckIcon className="text-primary-bleu" />}
        {texte}
      </button>
    </div>
  );
};

export default SelectableButton;
