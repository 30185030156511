import React, { useState } from 'react';
import akata from '../../Assets/akata.png';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import EmailIcon from '@mui/icons-material/Email';
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import TwitterIcon from '@mui/icons-material/Twitter';
import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { Menu, MenuItem, IconButton } from '@mui/material';

function Link({ texte, Icon, lien }) {
    return (
        <button 
            onClick={() => window.open(lien, '_blank')} 
            className='font-bold text-[12px] text-secondary-raven pr-[15px] pl-[15px] pt-[3px] pb-[3px] bg-secondary-background border border-secondary-stroke rounded-2xl flex justify-between items-center mr-[5px]'
        >
            <Icon className='h-[10px] w-[10px] text-secondary-raven mr-[3px]' />
            {texte}
        </button>
    );
}
// CLIENTS CONTACTS CARD
function Card() {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div className='h-full w-full p-[20px] border-secondary-stroke rounded-xl border-[1px] bg-secondary-white'>
            <div className='flex justify-between items-start'>
                <div className='w-[220px]'>
                    <img src={akata} alt='' className='h-[60px] w-[60px]' />
                    <div className='mt-[15px]'>
                        <p className='text-[18px] font-bold'>AKATA GOAVANA</p>
                        <p className='font-bold text-[16px] text-secondary-raven'>akata-goavana.com</p>
                    </div>
                </div>
                <IconButton onClick={handleClick}>
                    <MoreHorizRoundedIcon className='h-[20px] w-[20px]' />
                </IconButton>
                <Menu
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    PaperProps={{
                        className: 'bg-secondary-background',
                    }}
                >
                    <MenuItem onClick={handleClose} className="flex items-center">
                        <EditOutlinedIcon className="h-[16px] w-[16px] mr-[5px] text-secondary-raven" />
                        Modifier
                    </MenuItem>
                    <MenuItem onClick={handleClose} className="flex items-center">
                        <DeleteOutlineOutlinedIcon className="h-[16px] w-[16px] mr-[5px] text-secondary-raven" />
                        Supprimer
                    </MenuItem>
                </Menu>
            </div>

            <div className='mt-[20px]'>
                <div className='flex'>
                    <Link texte={"E-Mail"} Icon={EmailIcon} lien={"#"} />
                    <Link texte={"Facebook"} Icon={FacebookOutlinedIcon} lien={"#"} />
                </div>
                <div className='flex mt-[5px]'>
                    <Link texte={"LinkedIn"} Icon={LinkedInIcon} lien={"#"} />
                    <Link texte={"Twitter"} Icon={TwitterIcon} lien={"#"} />
                </div>
            </div>
        </div>
    );
}

export default Card;

