import AddIcon from '@mui/icons-material/Add';
import SpaOutlinedIcon from '@mui/icons-material/SpaOutlined';
import OfflineBoltOutlinedIcon from '@mui/icons-material/OfflineBoltOutlined';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import Hbar from '../Components/horizontalbar';
import { TextField, IconButton } from '@mui/material';
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import { useState } from 'react';
import SmartToyOutlinedIcon from '@mui/icons-material/SmartToyOutlined';


const AgentExample = ({ IconProject, title }) => {
    return (
        <div className='flex justify-between items-center pl-[10px] pr-[10px] rounded-2xl border-[1px] border-secondary-stroke w-[250px] h-[60px] bg-secondary-background'>
            <div className='flex justify-center items-center'>
                <div className='p-[10px] bg-secondary-ciel rounded-xl justify-between items-center mr-[10px] border-[1px] border-secondary-stroke'>
                    <IconProject className='text-primary-bleu' />
                </div>
                <p className='font-bold'>{title}</p>
            </div>
            <AddIcon className='text-secondary-raven' />
        </div>
    );
}

const ListAgent = ({number, title}) => {
    return(
        <div className='flex justify-center items-center p-[10px] rounded-lg border-[1px] border-secondary-stroke space-x-[10px]'>
            <p>{title}</p>
            <div className='pr-[5px] pl-[5px] rounded-2xl bg-primary-jaune'>
                <p className='text-secondary-white font-bold'>{number}</p>
            </div>
        </div>
    )
}
function Agent() {
    const [inputValue, setInputValue] = useState("");

    const handleSend = () => {
        console.log("Prompt envoyé : ", inputValue);
        setInputValue(""); // Clear input after sending
    };

    return (
        <div className='p-[15px] h-screen flex flex-col'>
            <Hbar />
            <div className='flex-1 mt-[20px] pb-[20px] flex justify-between items-center border-secondary-stroke border-2 rounded-lg bg-secondary-ciel'>
                <div className="flex-1 mt-[20px] pb-[20px] flex flex-col justify-center items-center space-y-6 ">
                    
                    <h1 className="font-bold text-[35px]">Créer un Agent</h1>
                    <p className="w-[350px] text-center">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod dolore magna aliqua.
                    </p>
                    <div className="flex justify-between items-center w-[530px]">
                        <div className="space-y-6">
                            <AgentExample IconProject={SpaOutlinedIcon} title={"Environnement"} />
                            <AgentExample IconProject={OfflineBoltOutlinedIcon} title={"Énergie"} />
                        </div>
                        <div className="space-y-6">
                            <AgentExample IconProject={SchoolOutlinedIcon} title={"Éducation"} />
                            <AgentExample IconProject={PaidOutlinedIcon} title={"Finance"} />
                        </div>
                    </div>
                    {/* Input */}
                    <div className="w-[530px] rounded-2xl mt-auto flex justify-center items-center p-1 bg-white border-[1px] border-secondary-stroke">
                        <TextField
                            value={inputValue}
                            onChange={(e) => setInputValue(e.target.value)}
                            variant="outlined"
                            placeholder="Écrire un message..."
                            fullWidth
                            InputProps={{
                                style: {
                                    borderColor: 'white',
                                    borderWidth: '1px',
                                },
                            }}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: 'white',
                                    },
                                    '&:hover fieldset': {
                                        borderColor: 'white',
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: 'white',
                                    },
                                },
                            }}
                            className="mr-2"
                        />
                        <div>
                            <IconButton className=' h-[30px] w-[30px]' onClick={handleSend}>
                                <SendRoundedIcon className='text-secondary-raven'/>
                            </IconButton>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Agent;
