import Hbar from "../Components/horizontalbar";
import SearchInput from "../Components/searchbar";
import FilterDropdown from "../Components/deroulantList";
import TableList from "../Components/list";

// WEBSITE LIST PAGE
function Data() {
    return (
        <div className="p-[15px] h-screen flex flex-col">
            <Hbar />
            <div className="flex-1 border-secondary-stroke border-2 rounded-lg bg-secondary-ciel mt-[20px] overflow-auto">
                <div className="flex justify-between items-baseline mr-[10px] ml-[34px] pr-[5px] pl-[5px] mt-[15px]">
                    <p className="font-bold text-[20px]">Liste des sites</p>
                    <div className="flex justify-center items-center">
                        <SearchInput />
                        <FilterDropdown />
                    </div>
                </div>
                <TableList repeatCount={10} />
            </div>
        </div>
    );
}

export default Data;
