import './App.css';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Data from './Filter/Page/data';
import Nav from './Filter/Components/navigation';
import ClientList from './Filter/Page/clients';
import Filter from './Filter/Page/filter';
import Agent from './Filter/Page/agent';

function App() {
  return (
    <Router>
      <div className="flex">
        <Nav />
        <div className="flex-1">
          <Routes>
            <Route path="/donnees" element={<Data />} />
            <Route path="/clients" element={<ClientList />} />
            <Route path="/Filtre" element={<Filter />} />
            <Route path="/agent" element={<Agent />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
