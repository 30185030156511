import React, { useState } from 'react';
import { MenuItem, Select, InputLabel, FormControl, InputAdornment } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';

// LIST FILTER 
const FilterDropdown = () => {
  const [filter, setFilter] = useState('');

  const handleChange = (event) => {
    setFilter(event.target.value);
  };

  return (
    <div className="flex items-center justify-start space-x-2 w-[200px] ml-[8px]">
      <FormControl variant="outlined" className="w-full max-w-xs h-[40px]">
        <InputLabel htmlFor="filter-select" className="flex items-start justify-center">
          <FilterListIcon className="mr-2 h-[12px] w-[12px]" /> 
          <span className="text-sm">Filtre</span> 
        </InputLabel>
        <Select
          id="filter-select"
          value={filter}
          onChange={handleChange}
          label="Filtrer par"
          endAdornment={
            <InputAdornment position="start">
            </InputAdornment>
          }
          className=" h-[40px] flex items-start justify-center" 
          sx={{
            height: '40px',
            '& .MuiOutlinedInput-input': {
              padding: '10px 14px',
              height: '40px',
            },
            '& .MuiSelect-icon': {
              top: 'calc(50% - 12px)',
            },
          }}
        >
          <MenuItem value="nom">Nom</MenuItem>
          <MenuItem value="secteur">Secteur</MenuItem>
          <MenuItem value="localisation">Localisation</MenuItem>
        </Select>
      </FormControl>
    </div>
  );
};

export default FilterDropdown;
