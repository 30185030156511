import React, { useState } from 'react';
import { Autocomplete, TextField, Chip } from '@mui/material';

//LOCALISATION
// Options for the ComboBox
const options = [
  { label: 'France', value: 'france' },
  { label: 'Madagascar', value: 'madagascar' },
  { label: 'Canada', value: 'canada' },
  { label: 'USA', value: 'usa' }
];

function ComboBoxExample() {
  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleOptionChange = (event, newValue) => {
    setSelectedOptions(newValue);
  };

  const handleDelete = (optionToDelete) => {
    setSelectedOptions((prevOptions) => 
      prevOptions.filter((option) => option.value !== optionToDelete.value)
    );
  };

  return (
    <div className="w-[400px] bg-white p-4 rounded-lg mt-[10px] border-[1px] border-secondary-stroke ">
      <div className="flex items-center justify-between mb-2 pb-[15px]">
        <div className="flex items-center">
          <p className="font-bold text-[16px]">Location</p>
        </div>
      </div>

      {/* Autocomplete Component */}
      <Autocomplete
        multiple
        value={selectedOptions}
        onChange={handleOptionChange}
        options={options}
        getOptionLabel={(option) => option.label}
        renderInput={(params) => (
          <TextField {...params} label="Sélectionner un lieu" variant="outlined" />
        )}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              key={option.value}
              label={option.label}
              onDelete={() => handleDelete(option)}
              {...getTagProps({ index })}
              sx={{
                backgroundColor: '#DDDDDD',
                color: '#001751',
                '& .MuiChip-deleteIcon': {
                  color: '#001751',
                },
              }}
            />
          ))
        }
      />
    </div>
  );
}

export default ComboBoxExample;
