import { useNavigate, useLocation } from "react-router-dom";
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import SortOutlinedIcon from '@mui/icons-material/SortOutlined';
import LinkOutlinedIcon from '@mui/icons-material/LinkOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import SmartToyOutlinedIcon from '@mui/icons-material/SmartToyOutlined';
import akata from '../../Assets/akata.png';
import goavana from '../../Assets/goavana.png';

//SIDE BAR NAVIGATION
function Bouton({ texte, path, Icon }) {
    const navigate = useNavigate();
    const location = useLocation();
    const isActive = location.pathname === path;

    const handleClick = () => {
        navigate(path);
    };

    return (
        <button
            className={`flex items-center justify-start w-[160px] pr-2 pl-4 pt-4 pb-4 rounded-lg 
            ${isActive ? 'bg-secondary-stroke text-black font-bold' : 'bg-secondary-white text-secondary-pearl'}`}
            onClick={handleClick}
        >
            {Icon && <Icon className={`mr-3 ${isActive ? 'text-black' : 'text-secondary-pearl'}`} />}
            <span className={isActive ? 'font-bold' : ''}>{texte}</span>
        </button>
    );
}

function Nav() {
    return (
        <div className=" pl-[30px] pr-[30px] pt-[10px] pb-[10px] h-screen border-red-500 flex flex-col items-baseline">
            <div className="flex justify-center items-end mb-[30px]">
                <img src={goavana} alt="goavana" className="w-20 h-20" />
                <img src={akata} alt="akata" className="w-16 h-16 ml-5" />
            </div>
            <div className=" flex flex-col justify-between items-end h-full">
                <div className="flex flex-col">
                    <Bouton texte="Accueil" path="/" Icon={HomeOutlinedIcon} />
                    <Bouton texte="Agent" path="/agent" Icon={SmartToyOutlinedIcon} />
                    <Bouton texte="Filtre" path="/Filtre" Icon={SortOutlinedIcon} />
                    <Bouton texte="Donnees" path="/donnees" Icon={LinkOutlinedIcon} />
                    <Bouton texte="Clients" path="/clients" Icon={PeopleAltOutlinedIcon} />
                </div>
                <div className="flex flex-col">
                    <Bouton texte="Parametres" path="/parametres" Icon={SettingsOutlinedIcon} />
                    <Bouton texte="Se deconnecter" path="/deconnexion" Icon={LogoutOutlinedIcon} />
                </div>
            </div>
        </div>
    );
}

export default Nav;
