import React from 'react';
import { TextField, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

//SEARCH BAR
const SearchInput = () => {
  return (
    <div className="flex w-full items-start justify-start space-x-2">
      <TextField
        variant="outlined"
        placeholder="Recherche"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon className='h-[16px] w-[16px]' />
            </InputAdornment>
          ),
        }}
        sx={{
          height: '40px', 
          '& .MuiOutlinedInput-root': {
            height: '100%',
            '& fieldset': {
              borderColor: '#DDDDDD',
            },
            '&:hover fieldset': {
              borderColor: '#001751',
            },
            '&.Mui-focused fieldset': {
              borderColor: '#001751',
            },
          },
          '& .MuiInputBase-input': {
            height: '100%',
          },
        }}
        className="w-full max-w-sm"
      />
    </div>
  );
};

export default SearchInput;
