import SearchInput from "../Components/searchbar";
import FilterDropdown from "../Components/deroulantList";
import Hbar from "../Components/horizontalbar";
import Card from "../Components/card";

// CLIENT PAGE
function ClientList() {
    return (
        <div className="p-[15px] h-screen flex flex-col">
            <Hbar />
            <div className="flex-1 border-secondary-stroke border-2 rounded-lg bg-secondary-ciel mt-[20px] pb-[20px] pr-[10px] pl-[10px] overflow-auto">
                <div className="flex justify-between items-baseline pr-[5px] pl-[5px] mt-[15px]">
                    <p className="font-bold text-[20px]">Liste des sites</p>
                    <div className="flex justify-center items-center">
                        <SearchInput />
                        <FilterDropdown />
                    </div>
                </div>
                <div className="mt-[10px]">
                    <div className="flex justify-between items-baseline space-x-5">
                        <Card />
                        <Card />
                        <Card />
                        <Card />
                    </div>
                    <div className="flex justify-between items-baseline mt-[25px] space-x-5">
                        <Card />
                        <Card />
                        <Card />
                        <Card />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ClientList;
