import Hbar from "../Components/horizontalbar";
import CustomCheckbox from "../Components/checkbox";
import ComboBoxExample from "../Components/localisation";
import SelectableButton from "../Components/radio";
import SearchInput from "../Components/searchbar";
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import TableList from "../Components/list";

// FILTER PAGE 
function Filter() {
    return (
        <div className="p-[15px] h-screen flex flex-col">
            <Hbar />
            
            <div className="flex-1 border-secondary-stroke border-2 rounded-lg bg-secondary-ciel mt-[20px] pb-[20px] overflow-auto">
                <div className="flex items-center justify-between p-[15px] w-full">
                    <div className="w-[700px] flex-col flex justify-between">
                        <div className="flex justify-between items-center">
                            <SearchInput />
                            <button className="font-bold text-secondary-pearl flex justify-center items-center pr-[10px] pl-[10px] pt-[10px] pb-[10px] rounded-lg border border-secondary-stroke h-[40px]">
                                <CreateOutlinedIcon className="h-[20px] w-[20px] text-secondary-pearl" />
                            </button>
                        </div>

                        <div className="border p-4 rounded-lg mt-[10px]">
                            <p className="text-start font-bold text-[16px]">Liste des secteurs</p>
                            <div className="flex flex-wrap justify-start w-[700px] overflow-auto gap-[-100px] mt-[10px]">
                                <SelectableButton texte="services financiers" />
                                <SelectableButton texte="Restauration" />
                                <SelectableButton texte="Innovation" />
                                <SelectableButton texte="Industrie manufacturière" />
                                <SelectableButton texte="Sante" />
                                <SelectableButton texte="Developpement" />
                                <SelectableButton texte="Télécommunications" />
                                <SelectableButton texte="Industrie manufacturière" />
                                <SelectableButton texte="Restauration" />
                                <SelectableButton texte="Education" />
                                <SelectableButton texte="Industrie manufacturière" />
                                <SelectableButton texte="Education" />
                                <SelectableButton texte="Developpement" />
                                <SelectableButton texte="Innovation" />
                                <SelectableButton texte="Industrie manufacturière" />
                                <SelectableButton texte="Télécommunications" />
                                <SelectableButton texte="Industrie manufacturière" />
                            </div>
                        </div>
                    </div>
                    
                    <div>
                        <p className="font-bold">Taille</p>
                        <div>
                            <CustomCheckbox texte={"Petit"} />
                            <CustomCheckbox texte={"Moyen"} />
                            <CustomCheckbox texte={"Grand"} />
                        </div>
                        <ComboBoxExample />
                    </div>
                </div>
                <div>
                    <p className="font-bold pl-[20px]">Suggestions</p>
                    <TableList repeatCount={3} />
                </div>
            </div>
        </div>
    );
}

export default Filter;
