import React, { useState } from 'react';
import Checkbox from '@mui/material/Checkbox';
import { Typography } from '@mui/material';

//SOCIETY SIZE
const CustomCheckbox = ({ texte }) => {
  const [selectedValue, setSelectedValue] = useState(false);

  const handleChange = () => {
    setSelectedValue(prev => !prev);
  };

  return (
    <div
      className={`w-[400px] h-[43px] flex items-center justify-start px-4 py-2 rounded-lg mt-[5px] 
        ${selectedValue ? 'border-[rgb(0,23,81)] bg-#DDDDDD' : 'border-secondary-stroke'} 
        border-[2px] bg-white`}
    >
      <Checkbox
        checked={selectedValue}
        onClick={handleChange}
        value="selected"
        name="custom-checkbox"
        inputProps={{ 'aria-label': 'Custom Checkbox' }}
        sx={{
          color: 'lightgray',
          '&.Mui-checked': {
            color: '#001751',
          },
          '& .MuiSvgIcon-root': {
            borderRadius: 0, 
          },
        }}
      />
      <Typography variant="body1" className={`${selectedValue ? 'text-[#001751] font-bold' : 'text-black'} ml-2`}>
        {texte}
      </Typography>
    </div>
  );
};

export default CustomCheckbox;
