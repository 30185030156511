import React from 'react';
import { Chip } from '@mui/material';
import LinkIcon from '@mui/icons-material/Link';
import akata from "../../Assets/akata.png"

const data = [
  {
    id: ' ',
    nom: 'AKATA GOAVANA',
    lien: 'akata.goavana.com',
    secteurs: ['Innovation', 'Santé'],
    taille: 'Grand',
    localisation: 'Madagascar',
    avatar: akata, 
  },
];

// WEBSITE LIST 
const TableList = ({ repeatCount }) => {
  const repeatedData = Array.from({ length: repeatCount }, (_, i) => ({
    ...data[0],
    id: `${data[0].id}-${i + 1}` // generate unique ids for each duplicated row
  }));

  return (
    <div className="w-full p-4">
      <table className="min-w-full bg-white border border-gray-200 rounded-lg">
        <thead>
          <tr className="text-left bg-gray-100 border-b">
            <th className="py-2 px-4 text-[#6C757D]">ID</th>
            <th className="py-2 px-4 text-[#6C757D]">Nom</th>
            <th className="py-2 px-4 text-[#6C757D]">Lien</th>
            <th className="py-2 px-4 text-[#6C757D]">Secteurs</th>
            <th className="py-2 px-4 text-[#6C757D]">Taille</th>
            <th className="py-2 px-4 text-[#6C757D]">Localisation</th>
          </tr>
        </thead>
        <tbody>
          {repeatedData.map((item, index) => (
            <tr key={index} className="border-b">
              <td className="py-2 px-4">{item.id}</td>
              <td className="py-2 px-4 flex items-center space-x-2">
                <img src={item.avatar} alt={item.nom} className="w-8 h-8 rounded-full" />
                <span className='font-bold'>{item.nom}</span>
              </td>
              <td className="py-2 px-4">
                <a
                  href={`https://${item.lien}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-600 hover:underline flex items-center"
                >
                  <LinkIcon className="mr-1" /> {item.lien}
                </a>
              </td>
              <td className="py-2 px-4">
                <div className="flex space-x-2">
                  {item.secteurs.map((secteur, idx) => (
                    <Chip
                      key={idx}
                      label={secteur}
                      sx={{
                        backgroundColor: '#FEF3C7', 
                        color: '#FFBA13', 
                        fontWeight: 'bold',
                        border: '2px solid #FFBA13',
                      }}
                    />
                  ))}
                </div>
              </td>
              <td className="py-2 px-4 text-blue-600 font-semibold">{item.taille}</td>
              <td className="py-2 px-4">{item.localisation}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TableList;
