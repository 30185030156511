import React, { useState } from 'react';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import itady from "../../Assets/itady.png";
import Bouton from "./buton";
import NotificationBar from './notification';

//BORIZONTAL BAR NOTIFICATION
function Hbar() {
    const [isNotificationOpen, setNotificationOpen] = useState(false);

    const toggleNotification = () => {
        setNotificationOpen(!isNotificationOpen);
    };

    const formatDate = (date) => {
        const options = { day: 'numeric', month: 'long', year: 'numeric' };
        return date.toLocaleDateString('fr-FR', options);
    };

    const today = new Date();
    const formattedDate = formatDate(today);

    return (
        <div className='bg-primary-bleu rounded-lg flex justify-between items-baseline pl-[50px] pr-[30px] pt-[10px] pb-[10px] relative'>
            <div className='flex flex-col justify-start items-start'>
                <img src={itady} alt='itady' className='h-[25px] w-[60px]' />
                <p className='text-secondary-white'>{formattedDate}</p>
            </div>
            <div className='flex justify-center items-center'>
                <div onClick={toggleNotification}>
                    <Bouton Icon={NotificationsNoneOutlinedIcon} />
                </div>
                <Bouton Icon={AddOutlinedIcon} />
            </div>

            {isNotificationOpen && (
                <div className='absolute z-10 top-full right-0 mt-4 w-[400px] bg-white shadow-lg rounded-lg border border-secondary-stroke'>
                    <div className='p-4 flex justify-between items-start'>
                        <h3 className='text-lg font-semibold'>Notifications</h3>
                        <button className="text-sm text-blue-600 float-right">Marquer comme lu</button>
                    </div>
                    <div className='border-t'>
                        <NotificationBar />
                        <NotificationBar />
                        <NotificationBar />
                    </div>
                    <div className='p-4 border-t text-center'>
                        <button className='text-blue-600'>Voir tout</button>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Hbar;
